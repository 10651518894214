import React from 'react';
import { navigate } from 'gatsby';

const Page = ({ location }) => {
    React.useLayoutEffect(() => {
        if (location != null && location.pathname != null) {
            if (location.search != null) {
                navigate(`/is${location.pathname}${location.search}`);
            } else {
                navigate(`/is${location.pathname}`);
            }
        } else {
            navigate(`/is/forgotpassword`);
        }
    }, [location]);
    return null;
};

export default Page;
